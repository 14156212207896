import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { serializeNode } from "../utils/wysiwyg"
import fetchJsonp from "fetch-jsonp"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters"

import renderHTML from "react-render-html"

require("es6-promise").polyfill()

const getURL = function getURL() {
  return typeof window !== "undefined" ? window.location.href : ""
}

export default function VacanciesListPage({ props }) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers at Chichester College Group</title>
      </Helmet>

      <div className="home vacancies-home">
        <section className="app-content">
          {vacancyLoading && (
            <div className="app-content-content">
              <div className="container">
                <div className="app-content-spacer"></div>
              </div>
            </div>
          )}
          <div className="app-content-content">
            <div className="container">
              {!vacancyLoading && (
                <h1>
                  Careers <span>at Chichester College Group</span>
                </h1>
              )}
              <div className="content-container">
                <SmartFeedFiltersComponent
                  vacancyLoading={vacancyLoading}
                  setVacancyLoading={setVacancyLoading}
                  apiKey={"3Wh-wj_AosfQ4Ku6eyWeu0nsoFzIGqGMNXZnYpHaSug"}
                  group={true}
                  showLocationFilter={true}
                  gdprKey="qDLqYFLXGvdpPKRe1ghrYvyyPo7rfAJ0PzfmYP5BCoQ"
                  CompanyGroupName="Chichester College Group"
                  VacancyPageBaseURL="https://chichestercollegegroupcareers.co.uk/vacancies/vacancy/"
                  ReturnToSearchURL="https://chichestercollegegroupcareers.co.uk/vacancies/"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
