import React, { useState, useEffect } from "react"
import { RouteComponentProps } from "@reach/router"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import fetchJsonp from "fetch-jsonp"
require("es6-promise").polyfill()

const smartfeedNotFound = ({ id = 1 }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="notfound">
        <section className="app-content">
          <div className="app-content-spacer">
            <div className="app-content-content">
              <div className="container">
                <h1>Sorry, we couldn't find that page.</h1>
                <div className="app-hero-button-wrap">
                  <a href="/" className="btn btn-primary button-primary-hero">
                    Go back
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default smartfeedNotFound
